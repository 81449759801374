@use '@/styles/utils/mixins.scss' as *;

.offerCard {
  height: 100%;
  width: 100%;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  padding: 2.5rem 1.875rem;
  display: flex;
  flex-direction: column;
  background-color: $white;
  transition: all 0.2s ease-in-out;

  &:hover {
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    border-color: $primaryAlt;
    cursor: pointer;
  }

  :global {
    .offerCardBtn {
      max-width: 17.5rem;
      @include mob() {
        max-width: 100%;
      }
    }
  }

  @include mob() {
    width: 100%;
  }

  .offerCardBody {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .textWrapper {
      h2 {
        margin-bottom: 1.25rem;
        font-weight: 300;
      }
    }

    .description {
      color: $neutral08;
    }
  }
}
